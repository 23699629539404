import React from 'react';
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import Home from '../screens/landingPage/home';
import Login from '../screens/account/login';
import NotFound from '../screens/landingPage/notFound';
import ExampleLoader from '../screens/landingPage/exampleLoader';
import RootLayout from '../layout/rootLayout';
import EmployerLanding from '../screens/landingPage/employer/employer';
import CreateCandidate from '../screens/account/createCandidate';
import CandidateLanding from '../screens/landingPage/candidate/candidate';
import VerifyOTP from '../screens/account/verifyOTP';
import CreateEmployer from '../screens/account/createEmployer';
import VerifyEmployerOTP from '../screens/account/verifyEmployerOTP';
import SearchJobs from '../screens/jobs/searchJobs/searchJobs';
import CandidateLogin from '../screens/account/candidateLogin';
import EmployerLogin from '../screens/account/employerLogin';
import Employers from '../screens/employers/employers';
import Employer from '../screens/employer/employer';
import JobDetails from '../screens/jobs/jobDetails/jobDetails';
import EditProfile from '../screens/profile/editProfile/editProfile';
import SavedJobs from '../screens/profile/savedJobs/savedJobs';
import AppliedJobs from '../screens/profile/appliedJobs/appliedJobs';
import EmployerDashboard from '../screens/employerDashboard/employerDashboard';
import CompanyProfile from '../screens/companyProfile/companyProfile';
import PostJob from '../screens/postJob/postJob';
import JobListing from '../screens/jobListing/jobListing';
import EditJob from '../screens/editJob/editJob';
import Applicants from '../screens/applicantsByJob/applicantsByJob';
import ApplyJob from '../screens/applyJob/applyJob';
import Settings from '../screens/profile/settings/settings';
import CvWebsite from '../screens/profile/cvWebsite/cvWebsite';
import CvWebsiteBuild from '../screens/cvWebsite/cvWebsite';
import SelectTemplate from '../screens/selectTemplaye/selectTemplate';
import Chat from '../screens/messages/message';
import ViewProfile from '../screens/viewProfile/viewProfile';
import Sidebar from '../screens/messages/sideBar';
import MessageHome from '../screens/messages/messageHome';
import CandidateMessage from '../screens/messages/candidate/messageHome';
import MySchedule from '../screens/mySchedule/mySchedule';
import Interviews from '../screens/profile/interviews/interviews';
import CandidateSearch from '../screens/candidateSearch/candidateSearch';
import Testimonial from '../screens/testimonial/testimonial';
import AdminDashboard from '../screens/admin/dashboard/adminDashboard';
import AdminLogin from '../screens/admin/adminLogin';
import AdminCandidates from '../screens/admin/adminCandidates';
import AdminSearchCandidate from '../screens/admin/adminSearchCandidate';
import AdminEmployers from '../screens/admin/adminEmployers';
import AdminSearchEmployer from '../screens/admin/adminSearchEmployer';
import AdminJobs from '../screens/admin/adminJobs';
import NotActivated from '../screens/account/notActivated';
import Suspended from '../screens/account/suspended';
import Companies from '../screens/companies/companies';
import Articles from '../screens/articles/articles';
import ArticleDetailPage from '../common/articleDetailPage';
import ArticlesEmployer from '../screens/articlesEmployer/articlesEmployer';
import EmployerRoute from './employerRoutes';
import CandidateRoute from './candidateRoutes';
import AdminRoute from './adminRoutes';
import AccessDenied from '../common/accessDenied';
import ForgotPassword from '../screens/forgotPasswordUser/forgotPassword';
import VerifyForgotPasswordOTP from '../screens/forgotPasswordUser/verifyForgotPasswordOTP';
import CreateNewPassword from '../screens/forgotPasswordUser/createNewPassword';
import EmployerForgotPassword from '../screens/forgetPasswordEmployer/employerForgotPassword';
import EmployerVerifyForgotPasswordOTP from '../screens/forgetPasswordEmployer/employerVerifyforgotPasswordOTP';
import EmployerCreateNewPassword from '../screens/forgetPasswordEmployer/employerCreateNewPassword';
import TermsOfService from '../screens/termsOfService/termsOfService';
import PrivacyPolicy from '../screens/privacyPolicy/privacyPolicy';
import ContactUs from '../screens/contactUs/contactUs';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<RootLayout />}>
        <Route index element={<CandidateLanding />} />
        <Route path="/login" element={<Login />} />
        <Route path="/example-loader" element={<ExampleLoader />} />
        <Route path="/employer-landing" element={<EmployerLanding />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/candidate-sign-up" element={<CreateCandidate />} />
        <Route path="/candidate-sign-up/:referralId" element={<CreateCandidate />} />
        <Route path="/employer-sign-up" element={<CreateEmployer />} />
        <Route path="/verify-OTP" element={<VerifyOTP />} />
        <Route path="/verify-employer-OTP" element={<VerifyEmployerOTP />} />
        <Route path="/search-jobs" element={<SearchJobs />} />
        <Route path="/candidate-login" element={<CandidateLogin />} />
        <Route path="/employer-login" element={<EmployerLogin />} />
        <Route path="/employers" element={<Employers />} />
        <Route path="/employers/:employer" element={<Employer />} />
        <Route path="/jobs/:job" element={<JobDetails />} />
        <Route path="/apply-job" element={<ApplyJob />} />
        <Route path="/public/:publicId" element={<CvWebsiteBuild />} />
        <Route path="/messages" element={<Chat />} />
        <Route path="/candidatemessages" element={<CandidateMessage />} />
        <Route path="/view-profile/:id" element={<ViewProfile />} />
        <Route path="/sidebar" element={<Sidebar />} />
        <Route path="/view-profile/:id" element={<ViewProfile />} />
        <Route path="/testimonial/:publicId" element={<Testimonial />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/not-activated" element={<NotActivated />} />
        <Route path="/suspended" element={<Suspended />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/article/:id" element={<ArticleDetailPage />} />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="/user-forgot-password" element={<ForgotPassword />} />
        <Route path="/user-verify-forgot-password" element={<VerifyForgotPasswordOTP />} />
        <Route path="/user-create-new-password" element={<CreateNewPassword />} />
        <Route path="/employer-forgot-password" element={<EmployerForgotPassword />} />
        <Route path="/employer-verify-forgot-password" element={<EmployerVerifyForgotPasswordOTP />} />
        <Route path="/employer-create-new-password" element={<EmployerCreateNewPassword />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />

        <Route element={<EmployerRoute />}>
          <Route path="/employer/dashboard" element={<EmployerDashboard />} />
          <Route path="/employer/profile" element={<CompanyProfile />} />
          <Route path="/employer/create-job" element={<PostJob />} />
          <Route path="/employer/edit-job" element={<EditJob />} />
          <Route path="/employer/job-listing" element={<JobListing />} />
          <Route path="/employer/applicants/:jobId" element={<Applicants />} />
          <Route path="/employer/schedule" element={<MySchedule />} />
          <Route path="/employer/candidate-search" element={<CandidateSearch />} />
          <Route path="/employer/articles" element={<ArticlesEmployer />} />
          <Route path="/employer/messages" element={<MessageHome />} />
        </Route>

        <Route element={<CandidateRoute />}>
          <Route path="/profile" element={<EditProfile />} />
          <Route path="/profile/settings" element={<Settings />} />
          <Route path="/profile/saved-jobs" element={<SavedJobs />} />
          <Route path="/profile/applied-jobs" element={<AppliedJobs />} />
          <Route path="/profile/cv-website" element={<CvWebsite />} />
          <Route path="/profile/interviews" element={<Interviews />} />
          <Route path="/profile/messages" element={<CandidateMessage />} />
          <Route path="/profile/cv-website/select" element={<SelectTemplate />} />
          <Route path="/profile/cv-website/build" element={<CvWebsiteBuild />} />
        </Route>

        <Route element={<AdminRoute />}>
          <Route path="/admin-candidates" element={<AdminCandidates />} />
          <Route path="/admin-search-candidate" element={<AdminSearchCandidate />} />
          <Route path="/admin-employers" element={<AdminEmployers />} />
          <Route path="/admin-search-employer" element={<AdminSearchEmployer />} />
          <Route path="/admin-jobs" element={<AdminJobs />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
        </Route>
      </Route>
    </>
  )
);

export default router;
