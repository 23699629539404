const WorkExperienceCard = ({ work }) => {
  return (
    <div className="border-gray-300 flex flex-col">
      <div className="text-template6-purple-1 text-2xl font-bold">{work?.position}</div>
      <div className="flex items-center gap-x-3">
        <div className="text-gray-600 text-lg">{work?.companyName}</div> |
        <div className="text-lg text-gray-600 rounded-2xl">
          {work?.startMonth} {work?.startYear} -{' '}
          {work?.endYear === 99 ? 'Current' : `${work?.startMonth} ${work?.endYear}`}
        </div>
      </div>

      {/* <div className="font-medium text-xs text-gray-600 mt-2">{work?.description}</div> */}
      <div className="w-full mt-2" dangerouslySetInnerHTML={{ __html: work?.description }}></div>
    </div>
    //  bg-oppty-blue
  );
};

export default WorkExperienceCard;
