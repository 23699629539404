const WorkExperienceCard = ({ work }) => {
  return (
    <div className="border-b-[1px] border-gray-300 flex flex-col lg:flex-row gap-5">
      <div className="w-full lg:w-1/2">
        <div className="text-black font-bold text-3xl">{work?.position}</div>
        <div className="bg-transparent border-[1px] border-template5-blue-1 w-fit px-4 py-1.5 text-sm font-semibold text-template5-blue-1 rounded-2xl mt-2">
          {work?.startMonth} {work?.startYear} -{' '}
          {work?.endYear === 99 ? 'Current' : `${work?.startMonth} ${work?.endYear}`}
        </div>
      </div>
      <div className="w-full lg:w-1/2">
        <div className="text-black text-2xl font-bold">{work?.companyName}</div>
        {/* <div className="font-medium text-sm text-black/60">{work?.description}</div> */}
        <div className="w-full mt-2" dangerouslySetInnerHTML={{ __html: work?.description }}></div>
      </div>
    </div>
  );
};

export default WorkExperienceCard;
