import * as Yup from 'yup';

const localLang = localStorage.getItem('lan');
export const googleCliendId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const errorMessages = {
  0: {
    // English
    firstNameRequired: 'First Name is required',
    firstNameMax: 'First Name cannot exceed 100 characters',
    lastNameRequired: 'Last Name is required',
    lastNameMax: 'Last Name cannot exceed 100 characters',
    emailRequired: 'Email address is required',
    emailInvalid: 'Please enter a valid email address',
    emailMax: 'Email cannot exceed 200 characters',
    passwordRequired: 'Password is required',
    uppercaseRequired: 'Uppercase is required',
    lowercaseRequired: 'Lowercase is required',
    passwordMin: 'Your password must have at least 8 characters',
    passwordMax: 'Password cannot exceed 128 characters',
    companyNameRequired: 'Company Name is required',
    companyNameMax: 'Company Name cannot exceed 100 characters',
    jobTitleRequired: 'Job Title is required',
    jobDescriptionRequired: 'Job Description is required',
    jobRequirementsRequired: 'Job Requirements is required',
    jobEmploymentTypeRequired: 'Select job employment type',
    jobResponsibilitiesRequired: 'Job Responsibilities is required',
    workPolicyRequired: 'Select work policy type',
    salaryTypeRequired: 'Select salary type',
    currencyTypeRequired: 'Select currency type',
    experienceRequired: 'Select required experience',
    skillsRequired: 'Skills are required',
    skillsMin: 'At least one skill is required',
    minSalaryRequired: 'Min salary is required',
    minSalaryTypeError: 'Min salary must be a number',
    maxSalaryRequired: 'Max salary is required',
    maxSalaryTypeError: 'Max salary must be a number',
    salutationRequired: 'Salutation is required',
    fullNameRequired: 'Full Name is required',
    organizationRequired: 'Organization is required',
    positionRequired: 'Position is required',
    testimonialRequired: 'Testimonial is required',
    testimonialMax: 'Testimonial cannot exceed 200 characters',
    profileExperienceMax: 'Description cannot exceed 1000 characters',
    userNameRequired: 'Username is required',
    userNameMax: 'Username cannot exceed 200 characters',
    firstNameMin: 'First Name must be at least 2 characters',
    firstNameMaxGD: 'First Name cannot be more than 50 characters',
    lastNameMin: 'Last Name must be at least 2 characters',
    lastNameMaxGD: 'Last Name cannot be more than 50 characters',
    emailInvalidGD: 'Invalid email address',
    emailRequiredGD: 'Email is required',
    areaCodeRequiredGD: 'Area Code is required',
    mobileRequiredGD: 'Mobile number is required',
    addressRequiredGD: 'Address is required',
    addressMaxGD: 'Address cannot be more than 100 characters',
    stateRequiredGD: 'State is required',
    stateMaxGD: 'State cannot be more than 50 characters',
    cityRequiredGD: 'City is required',
    cityMaxGD: 'City cannot be more than 50 characters',
    countryRequiredGD: 'Country is required',
    countryMaxGD: 'Country cannot be more than 50 characters',
    postalRequiredGD: 'Postal code is required',
    aboutMeRequiredGD: 'About Me is required',
    titleRequiredGD: 'Title is required',
    industryRequiredGD: 'Industry is required',
    passwordsMustMatch: 'Passwords must match',
    confirmPasswordRequired: 'Verify Passwords is required',
    endMonthRequired: 'End Month is required',
    endYearRequired: 'End Year is required',
    endDateBeforeStartDate: 'End date cannot be before start date',
    institutionRequired: 'Institution is required',
    institutionMax: 'Institution cannot exceed 100 characters',
    qualificationRequired: 'Qualification is required',
    qualificationMax: 'Qualification cannot exceed 100 characters',
    courseRequired: 'Course is required',
    courseMax: 'Course cannot exceed 100 characters',
    startMonthRequired: 'Start month is required',
    startMonthInvalid: 'Invalid start month',
    startYearRequired: 'Start year is required',
    startYearMin: 'Start year cannot be before 1900',
    startYearMax: 'Start year cannot be in the future',
    endMonthInvalid: 'Invalid end month',
    endYearInvalid: 'Invalid end year',
    countryRequired: 'Country is required',
    countryMax: 'Country name cannot exceed 100 characters',
    descriptionMax: 'Description cannot exceed 1000 characters',
    highlightRequired: 'Highlight is required',
    linkRequired: 'Link is required',
    languageRequired: 'Language is required',
    proficiencyRequired: 'Proficiency is required',
    socialRequired: 'Social media link is required',
    skillRequired: 'Skill is required',
    fileRequired: 'Image is required',
    levelRequired: 'Level is required',
    aboutMeMax: 'About Me cannot exceed 1000 characters',
    highlightMax: 'Highlight cannot exceed 100 characters',
    fullNameMax: 'Full Name cannot exceed 100 characters',
    messageMax: 'Message cannot exceed 500 characters',
    messageRequired: 'Message is required'
  },
  1: {
    // Simplified Chinese
    firstNameRequired: '名字是必填项',
    firstNameMax: '名字不能超过100个字符',
    lastNameRequired: '姓氏是必填项',
    lastNameMax: '姓氏不能超过100个字符',
    emailRequired: '电子邮件地址是必填项',
    emailInvalid: '请输入有效的电子邮件地址',
    emailMax: '电子邮件不能超过200个字符',
    passwordRequired: '密码是必填项',
    uppercaseRequired: '必须包含大写字母',
    lowercaseRequired: '必须包含小写字母',
    passwordMin: '密码至少需要8个字符',
    passwordMax: '密码不能超过128个字符',
    companyNameRequired: '公司名称是必填项',
    companyNameMax: '公司名称不能超过100个字符',
    jobTitleRequired: '职位名称是必填项',
    jobDescriptionRequired: '职位描述是必填项',
    jobRequirementsRequired: '职位要求是必填项',
    jobEmploymentTypeRequired: '请选择工作类型',
    jobResponsibilitiesRequired: '职责是必填项',
    workPolicyRequired: '请选择工作政策类型',
    salaryTypeRequired: '请选择薪资类型',
    currencyTypeRequired: '请选择货币类型',
    experienceRequired: '请选择所需经验',
    skillsRequired: '技能是必填项',
    skillsMin: '至少需要一种技能',
    minSalaryRequired: '最低工资是必填项',
    minSalaryTypeError: '最低工资必须是数字',
    maxSalaryRequired: '最高工资是必填项',
    maxSalaryTypeError: '最高工资必须是数字',
    salutationRequired: '称谓是必填项',
    fullNameRequired: '姓名是必填项',
    organizationRequired: '组织名称是必填项',
    positionRequired: '职位是必填项',
    testimonialRequired: '评价是必填项',
    testimonialMax: '评价不能超过200个字符',
    profileExperienceMax: '描述不能超过1000个字符',
    userNameRequired: '用户名是必填项',
    userNameMax: '用户名不能超过200个字符',
    firstNameMin: '名字至少要有2个字符',
    firstNameMaxGD: '名字不能超过50个字符',
    lastNameMin: '姓氏至少要有2个字符',
    lastNameMaxGD: '姓氏不能超过50个字符',
    emailInvalidGD: '无效的电子邮件地址',
    emailRequiredGD: '电子邮件是必填项',
    areaCodeRequiredGD: '区号是必填项',
    mobileRequiredGD: '手机号码是必填项',
    addressRequiredGD: '地址是必填项',
    addressMaxGD: '地址不能超过100个字符',
    stateRequiredGD: '州是必填项',
    stateMaxGD: '州不能超过50个字符',
    cityRequiredGD: '城市是必填项',
    cityMaxGD: '城市不能超过50个字符',
    countryRequiredGD: '国家是必填项',
    countryMaxGD: '国家不能超过50个字符',
    postalRequiredGD: '邮政编码是必填项',
    aboutMeRequiredGD: '自我介绍是必填项',
    titleRequiredGD: '标题是必填项',
    industryRequiredGD: '行业是必填项',
    passwordsMustMatch: '密码必须匹配',
    confirmPasswordRequired: '验证密码是必填项',
    endMonthRequired: '结束月份是必填项',
    endYearRequired: '结束年份是必填项',
    endDateBeforeStartDate: '结束日期不能早于开始日期',
    institutionRequired: '机构是必需的',
    institutionMax: '机构名称不能超过100个字符',
    qualificationRequired: '学历是必需的',
    qualificationMax: '学历不能超过100个字符',
    courseRequired: '课程是必需的',
    courseMax: '课程名称不能超过100个字符',
    startMonthRequired: '开始月份是必需的',
    startMonthInvalid: '无效的开始月份',
    startYearRequired: '开始年份是必需的',
    startYearMin: '开始年份不能早于1900年',
    startYearMax: '开始年份不能超过当前年份',
    endMonthInvalid: '无效的结束月份',
    endYearInvalid: '无效的结束年份',
    countryRequired: '国家是必需的',
    countryMax: '国家名称不能超过100个字符',
    descriptionMax: '描述不能超过1000个字符',
    highlightRequired: '重点是必需的',
    linkRequired: '链接是必需的',
    languageRequired: '语言是必需的',
    proficiencyRequired: '熟练程度是必需的',
    socialRequired: '社交媒体链接是必需的',
    skillRequired: '技能是必需的',
    fileRequired: '必须上传图片',
    levelRequired: '级别是必需的',
    aboutMeMax: '关于我的描述不能超过1000个字符',
    highlightMax: '重点不能超过100个字符',
    fullNameMax: '全名不能超过100个字符',
    messageMax: '信息不能超过500个字符',
    messageRequired: '信息是必需的'
  },
  2: {
    // Malay
    firstNameRequired: 'Nama pertama diperlukan',
    firstNameMax: 'Nama pertama tidak boleh melebihi 100 aksara',
    lastNameRequired: 'Nama akhir diperlukan',
    lastNameMax: 'Nama akhir tidak boleh melebihi 100 aksara',
    emailRequired: 'Alamat e-mel diperlukan',
    emailInvalid: 'Sila masukkan alamat e-mel yang sah',
    emailMax: 'E-mel tidak boleh melebihi 200 aksara',
    passwordRequired: 'Kata laluan diperlukan',
    uppercaseRequired: 'Huruf besar diperlukan',
    lowercaseRequired: 'Huruf kecil diperlukan',
    passwordMin: 'Kata laluan anda mesti mempunyai sekurang-kurangnya 8 aksara',
    passwordMax: 'Kata laluan tidak boleh melebihi 128 aksara',
    companyNameRequired: 'Nama Syarikat diperlukan',
    companyNameMax: 'Nama Syarikat tidak boleh melebihi 100 aksara',
    jobTitleRequired: 'Tajuk Pekerjaan diperlukan',
    jobDescriptionRequired: 'Penerangan Pekerjaan diperlukan',
    jobRequirementsRequired: 'Keperluan Pekerjaan diperlukan',
    jobEmploymentTypeRequired: 'Pilih jenis pekerjaan',
    jobResponsibilitiesRequired: 'Tanggungjawab Pekerjaan diperlukan',
    workPolicyRequired: 'Pilih jenis dasar kerja',
    salaryTypeRequired: 'Pilih jenis gaji',
    currencyTypeRequired: 'Pilih jenis mata wang',
    experienceRequired: 'Pilih pengalaman yang diperlukan',
    skillsRequired: 'Kemahiran diperlukan',
    skillsMin: 'Sekurang-kurangnya satu kemahiran diperlukan',
    minSalaryRequired: 'Gaji minimum diperlukan',
    minSalaryTypeError: 'Gaji minimum mesti berupa nombor',
    maxSalaryRequired: 'Gaji maksimum diperlukan',
    maxSalaryTypeError: 'Gaji maksimum mesti berupa nombor',
    salutationRequired: 'Gelaran diperlukan',
    fullNameRequired: 'Nama penuh diperlukan',
    organizationRequired: 'Organisasi diperlukan',
    positionRequired: 'Jawatan diperlukan',
    testimonialRequired: 'Testimoni diperlukan',
    testimonialMax: 'Testimoni tidak boleh melebihi 200 aksara',
    profileExperienceMax: 'Deskripsi tidak boleh melebihi 1000 aksara',
    userNameRequired: 'Nama pengguna diperlukan',
    userNameMax: 'Nama pengguna tidak boleh melebihi 200 aksara',
    firstNameMin: 'Nama pertama mesti sekurang-kurangnya 2 aksara',
    firstNameMaxGD: 'Nama pertama tidak boleh melebihi 50 aksara',
    lastNameMin: 'Nama akhir mesti sekurang-kurangnya 2 aksara',
    lastNameMaxGD: 'Nama akhir tidak boleh melebihi 50 aksara',
    emailInvalidGD: 'Alamat e-mel tidak sah',
    emailRequiredGD: 'E-mel diperlukan',
    areaCodeRequiredGD: 'Kod Kawasan diperlukan',
    mobileRequiredGD: 'Nombor telefon bimbit diperlukan',
    addressRequiredGD: 'Alamat diperlukan',
    addressMaxGD: 'Alamat tidak boleh melebihi 100 aksara',
    stateRequiredGD: 'Negeri diperlukan',
    stateMaxGD: 'Negeri tidak boleh melebihi 50 aksara',
    cityRequiredGD: 'Bandar diperlukan',
    cityMaxGD: 'Bandar tidak boleh melebihi 50 aksara',
    countryRequiredGD: 'Negara diperlukan',
    countryMaxGD: 'Negara tidak boleh melebihi 50 aksara',
    postalRequiredGD: 'Kod pos diperlukan',
    aboutMeRequiredGD: 'Tentang Saya diperlukan',
    titleRequiredGD: 'Tajuk diperlukan',
    industryRequiredGD: 'Industri diperlukan',
    passwordsMustMatch: 'Kata laluan mesti sepadan',
    confirmPasswordRequired: 'Sahkan kata laluan diperlukan',
    endMonthRequired: 'Bulan tamat diperlukan',
    endYearRequired: 'Tahun tamat diperlukan',
    endDateBeforeStartDate: 'Tarikh tamat tidak boleh sebelum tarikh mula',
    institutionRequired: 'Institusi diperlukan',
    institutionMax: 'Nama institusi tidak boleh melebihi 100 aksara',
    qualificationRequired: 'Kelayakan diperlukan',
    qualificationMax: 'Kelayakan tidak boleh melebihi 100 aksara',
    courseRequired: 'Kursus diperlukan',
    courseMax: 'Nama kursus tidak boleh melebihi 100 aksara',
    startMonthRequired: 'Bulan mula diperlukan',
    startMonthInvalid: 'Bulan mula tidak sah',
    startYearRequired: 'Tahun mula diperlukan',
    startYearMin: 'Tahun mula tidak boleh sebelum 1900',
    startYearMax: 'Tahun mula tidak boleh melebihi tahun semasa',
    endMonthInvalid: 'Bulan tamat tidak sah',
    endYearInvalid: 'Tahun tamat tidak sah',
    countryRequired: 'Negara diperlukan',
    countryMax: 'Nama negara tidak boleh melebihi 100 aksara',
    descriptionMax: 'Deskripsi tidak boleh melebihi 1000 aksara',
    highlightRequired: 'Sorotan diperlukan',
    linkRequired: 'Pautan diperlukan',
    languageRequired: 'Bahasa diperlukan',
    proficiencyRequired: 'Tahap kemahiran diperlukan',
    socialRequired: 'Pautan media sosial diperlukan',
    skillRequired: 'Kemahiran diperlukan',
    fileRequired: 'Imej diperlukan',
    levelRequired: 'Tahap diperlukan',
    aboutMeMax: 'Tentang Saya tidak boleh melebihi 1000 aksara',
    highlightMax: 'Sorotan tidak boleh melebihi 100 aksara',
    fullNameMax: 'Nama Penuh tidak boleh melebihi 100 aksara',
    messageMax: 'Mesej tidak boleh melebihi 500 aksara',
    messageRequired: 'Mesej diperlukan'
  }
};

const getErrorMessage = (key) => errorMessages[localLang]?.[key] || errorMessages[0][key];

export const createCandidateSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .required(getErrorMessage('firstNameRequired'))
    .max(100, getErrorMessage('firstNameMax')),
  lastName: Yup.string().trim().required(getErrorMessage('lastNameRequired')).max(100, getErrorMessage('lastNameMax')),
  email: Yup.string()
    .trim()
    .required(getErrorMessage('emailRequired'))
    .email(getErrorMessage('emailInvalid'))
    .max(200, getErrorMessage('emailMax')),
  password: Yup.string()
    .required(getErrorMessage('passwordRequired'))
    .matches(/[A-Z]/, getErrorMessage('uppercaseRequired'))
    .matches(/[a-z]/, getErrorMessage('lowercaseRequired'))
    .min(8, getErrorMessage('passwordMin'))
    .max(128, getErrorMessage('passwordMax'))
});

export const createEmployerSchema = Yup.object({
  companyName: Yup.string()
    .trim()
    .required(getErrorMessage('companyNameRequired'))
    .max(100, getErrorMessage('companyNameMax')),
  email: Yup.string()
    .trim()
    .required(getErrorMessage('emailRequired'))
    .email(getErrorMessage('emailInvalid'))
    .max(200, getErrorMessage('emailMax')),
  password: Yup.string()
    .required(getErrorMessage('passwordRequired'))
    .matches(/[A-Z]/, getErrorMessage('uppercaseRequired'))
    .matches(/[a-z]/, getErrorMessage('lowercaseRequired'))
    .min(8, getErrorMessage('passwordMin'))
    .max(128, getErrorMessage('passwordMax'))
});

export const loginEmployerSchema = Yup.object({
  email: Yup.string()
    .trim()
    .required(getErrorMessage('emailRequired'))
    .email(getErrorMessage('emailInvalid'))
    .max(200, getErrorMessage('emailMax')),
  password: Yup.string()
    .required(getErrorMessage('passwordRequired'))
    .matches(/[A-Z]/, getErrorMessage('uppercaseRequired'))
    .matches(/[a-z]/, getErrorMessage('lowercaseRequired'))
    .min(8, getErrorMessage('passwordMin'))
    .max(128, getErrorMessage('passwordMax'))
});

// Example: Login Candidate Schema
export const loginCandidateSchema = Yup.object({
  email: Yup.string()
    .trim()
    .required(getErrorMessage('emailRequired'))
    .email(getErrorMessage('emailInvalid'))
    .max(200, getErrorMessage('emailMax')),
  password: Yup.string()
    .required(getErrorMessage('passwordRequired'))
    .matches(/[A-Z]/, getErrorMessage('uppercaseRequired'))
    .matches(/[a-z]/, getErrorMessage('lowercaseRequired'))
    .min(8, getErrorMessage('passwordMin'))
    .max(128, getErrorMessage('passwordMax'))
});

export const addJobSchema = Yup.object({
  jobTitle: Yup.string().required(getErrorMessage('jobTitleRequired')).max(200, getErrorMessage('emailMax')),
  jobDescription: Yup.string().required(getErrorMessage('jobDescriptionRequired')),
  jobRequirements: Yup.string().required(getErrorMessage('jobRequirementsRequired')),
  jobEmploymentType: Yup.string().required(getErrorMessage('jobEmploymentTypeRequired')),
  jobResponsibilities: Yup.string().required(getErrorMessage('jobResponsibilitiesRequired')),
  workPolicy: Yup.string().required(getErrorMessage('workPolicyRequired')),
  salaryType: Yup.string().required(getErrorMessage('salaryTypeRequired')),
  currencyType: Yup.string().required(getErrorMessage('currencyTypeRequired')),
  experienceRequired: Yup.string().required(getErrorMessage('experienceRequired')),
  skills: Yup.array().min(1, getErrorMessage('skillsMin')).required(getErrorMessage('skillsRequired')),
  isSalaryFixed: Yup.boolean(),
  minSalary: Yup.number()
    .typeError(getErrorMessage('minSalaryTypeError'))
    .required(getErrorMessage('minSalaryRequired')),
  // maxSalary: Yup.number()
  //   .typeError(getErrorMessage('maxSalaryTypeError'))
  //   .required(getErrorMessage('maxSalaryRequired'))
  maxSalary: Yup.number()
    .typeError(getErrorMessage('maxSalaryTypeError'))
    .nullable()
    .test('is-required', getErrorMessage('maxSalaryRequired'), function (value) {
      const { isSalaryFixed } = this.parent;
      if (!isSalaryFixed) {
        return value !== undefined && value !== null;
      }
      return true;
    })
});

export const createTestimonialSchema = Yup.object({
  salutation: Yup.string().required(getErrorMessage('salutationRequired')),
  fullName: Yup.string().required(getErrorMessage('fullNameRequired')),
  organization: Yup.string().required(getErrorMessage('organizationRequired')),
  position: Yup.string().required(getErrorMessage('positionRequired')),
  testimonial: Yup.string().required(getErrorMessage('testimonialRequired')).max(200, getErrorMessage('testimonialMax'))
});

export const loginAdminSchema = Yup.object({
  userName: Yup.string().trim().required(getErrorMessage('userNameRequired')).max(200, getErrorMessage('userNameMax')),
  password: Yup.string()
    .required(getErrorMessage('passwordRequired'))
    .matches(/[A-Z]/, getErrorMessage('uppercaseRequired'))
    .matches(/[a-z]/, getErrorMessage('lowercaseRequired'))
    .min(8, getErrorMessage('passwordMin'))
    .max(128, getErrorMessage('passwordMax'))
});

export const generalDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, getErrorMessage('firstNameMin'))
    .max(50, getErrorMessage('firstNameMaxGD'))
    .required(getErrorMessage('firstNameRequired')),
  lastName: Yup.string()
    .min(2, getErrorMessage('lastNameMin'))
    .max(50, getErrorMessage('lastNameMaxGD'))
    .required(getErrorMessage('lastNameRequired')),
  salutation: Yup.string(),
  email: Yup.string().email(getErrorMessage('emailInvalidGD')).required(getErrorMessage('emailRequiredGD')),
  areaCode: Yup.string().required(getErrorMessage('areaCodeRequiredGD')),
  mobile: Yup.string()
    .matches(/^\d+$/, getErrorMessage('mobileRequiredGD'))
    .required(getErrorMessage('mobileRequiredGD')),
  address: Yup.string().max(100, getErrorMessage('addressMaxGD')).required(getErrorMessage('addressRequiredGD')),
  state: Yup.string().max(50, getErrorMessage('stateMaxGD')).required(getErrorMessage('stateRequiredGD')),
  city: Yup.string().max(50, getErrorMessage('cityMaxGD')).required(getErrorMessage('cityRequiredGD')),
  country: Yup.string().max(50, getErrorMessage('countryMaxGD')).required(getErrorMessage('countryRequiredGD')),
  postal: Yup.string().required(getErrorMessage('postalRequiredGD')),
  aboutMe: Yup.string().max(1000, getErrorMessage('aboutMeMax')).required(getErrorMessage('aboutMeRequiredGD')),
  title: Yup.string().max(100, getErrorMessage('titleRequiredGD')).required(getErrorMessage('titleRequiredGD')),
  industry: Yup.string().max(100, getErrorMessage('industryRequiredGD')).required(getErrorMessage('industryRequiredGD'))
});

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const candidateWorkExperienceSchema = Yup.object().shape({
  companyName: Yup.string()
    .required(getErrorMessage('companyNameRequired'))
    .max(100, getErrorMessage('companyNameMax')),
  position: Yup.string().required(getErrorMessage('positionRequired')).max(100, getErrorMessage('companyNameMax')),
  startMonth: Yup.string()
    .required(getErrorMessage('jobTitleRequired'))
    .oneOf(monthNames, getErrorMessage('emailInvalidGD')),
  startYear: Yup.number()
    .required(getErrorMessage('jobEmploymentTypeRequired'))
    .min(1900, getErrorMessage('passwordRequired'))
    .max(new Date().getFullYear(), getErrorMessage('emailInvalidGD')),
  endMonth: Yup.string()
    .required(getErrorMessage('passwordMin'))
    .oneOf([...monthNames, '99'], getErrorMessage('passwordMax')),
  endYear: Yup.number()
    .required(getErrorMessage('passwordMax'))
    .oneOf(
      [99, ...Array.from({ length: new Date().getFullYear() - 1899 }, (_, i) => 1900 + i)],
      getErrorMessage('companyNameRequired')
    ),
  country: Yup.string().required(getErrorMessage('countryRequiredGD')).max(100, getErrorMessage('countryMaxGD')),
  description: Yup.string().max(2000, getErrorMessage('profileExperienceMax'))
});

// export const candidateEducationSchema = Yup.object().shape({
//   institution: Yup.string()
//     .required(getErrorMessage('organizationRequired'))
//     .max(100, getErrorMessage('testimonialMax')),
//   qualification: Yup.string()
//     .required(getErrorMessage('jobEmploymentTypeRequired'))
//     .max(100, getErrorMessage('jobEmploymentTypeRequired')),
//   course: Yup.string().required(getErrorMessage('testimonialRequired')).max(100, getErrorMessage('testimonialMax')),
//   startMonth: Yup.string()
//     .required(getErrorMessage('firstNameRequired'))
//     .oneOf(monthNames, getErrorMessage('companyNameRequired')),
//   startYear: Yup.number()
//     .required(getErrorMessage('organizationRequired'))
//     .min(1900, getErrorMessage('passwordRequired'))
//     .max(new Date().getFullYear(), getErrorMessage('emailInvalidGD')),
//   endMonth: Yup.string()
//     .required(getErrorMessage('passwordMin'))
//     .oneOf([...monthNames, '99'], getErrorMessage('passwordMax')),
//   endYear: Yup.number()
//     .required(getErrorMessage('passwordMax'))
//     .oneOf(
//       [99, ...Array.from({ length: new Date().getFullYear() - 1899 }, (_, i) => 1900 + i)],
//       getErrorMessage('companyNameRequired')
//     ),
//   country: Yup.string().required(getErrorMessage('countryRequiredGD')).max(100, getErrorMessage('countryMaxGD')),
//   description: Yup.string().max(1000, getErrorMessage('profileExperienceMax'))
// });
export const candidateEducationSchema = Yup.object().shape({
  institution: Yup.string()
    .required(getErrorMessage('institutionRequired'))
    .max(100, getErrorMessage('institutionMax')),
  qualification: Yup.string()
    .required(getErrorMessage('qualificationRequired'))
    .max(100, getErrorMessage('qualificationMax')),
  course: Yup.string().required(getErrorMessage('courseRequired')).max(100, getErrorMessage('courseMax')),
  startMonth: Yup.string()
    .required(getErrorMessage('startMonthRequired'))
    .oneOf(monthNames, getErrorMessage('startMonthInvalid')),
  startYear: Yup.number()
    .required(getErrorMessage('startYearRequired'))
    .min(1900, getErrorMessage('startYearMin'))
    .max(new Date().getFullYear(), getErrorMessage('startYearMax')),
  endMonth: Yup.string()
    .required(getErrorMessage('endMonthRequired'))
    .oneOf([...monthNames, '99'], getErrorMessage('endMonthInvalid')),
  endYear: Yup.number()
    .required(getErrorMessage('endYearRequired'))
    .oneOf(
      [99, ...Array.from({ length: new Date().getFullYear() - 1899 }, (_, i) => 1900 + i)],
      getErrorMessage('endYearInvalid')
    ),
  country: Yup.string().required(getErrorMessage('countryRequired')).max(100, getErrorMessage('countryMax')),
  description: Yup.string().max(2000, getErrorMessage('descriptionMax'))
});

export const skillSchema = Yup.object({
  skill: Yup.string().required(getErrorMessage('skillRequired')),
  level: Yup.string().required(getErrorMessage('levelRequired'))
});

export const languageSchema = Yup.object({
  lang: Yup.string().required(getErrorMessage('languageRequired')),
  level: Yup.string().required(getErrorMessage('levelRequired'))
});

export const socialSchema = Yup.object({
  social: Yup.string().required(getErrorMessage('socialRequired')),
  link: Yup.string().required(getErrorMessage('linkRequired'))
});

export const portfolioSchema = Yup.object({
  link: Yup.string().required(getErrorMessage('linkRequired')),
  highlight: Yup.string().required(getErrorMessage('highlightRequired')),
  fileName: Yup.string().required(getErrorMessage('fileRequired'))
});

export const employerGeneralSchema = Yup.object().shape({
  companyName: Yup.string()
    .required(getErrorMessage('companyNameRequired'))
    .max(100, getErrorMessage('companyNameMax')),
  email: Yup.string().email(getErrorMessage('emailInvalidGD')).required(getErrorMessage('emailRequiredGD')),
  areaCode: Yup.string().required(getErrorMessage('areaCodeRequiredGD')),
  mobile: Yup.string()
    .matches(/^\d+$/, getErrorMessage('mobileRequiredGD'))
    .required(getErrorMessage('mobileRequiredGD')),
  address: Yup.string().required(getErrorMessage('addressRequiredGD')).max(100, getErrorMessage('addressMaxGD')),
  companyLocation: Yup.string()
    .required(getErrorMessage('companyLocationRequired'))
    .max(255, getErrorMessage('addressMaxGD')),
  companySize: Yup.string()
    .required(getErrorMessage('companySizeRequired'))
    .max(100, getErrorMessage('companyNameMax')),
  companyIndustry: Yup.string()
    .required(getErrorMessage('industryRequiredGD'))
    .max(100, getErrorMessage('industryRequiredGD')),
  postal: Yup.string()
    .matches(/^\d+$/, getErrorMessage('postalRequiredGD'))
    .required(getErrorMessage('postalRequiredGD')),
  companyDescription: Yup.string().test('len', getErrorMessage('descriptionMax'), function (value) {
    const plainText = stripHtmlTags(value);
    return plainText.length <= 1000;
  })
});

export const stripHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

export const createNewPasswordSchema = Yup.object({
  password: Yup.string()
    .required(getErrorMessage('passwordRequired'))
    .matches(/[A-Z]/, getErrorMessage('uppercaseRequired'))
    .matches(/[a-z]/, getErrorMessage('lowercaseRequired'))
    .min(8, getErrorMessage('passwordMin'))
    .max(128, getErrorMessage('passwordMax')),
  verifyPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], getErrorMessage('passwordsMustMatch'))
    .required(getErrorMessage('confirmPasswordRequired')),
  email: Yup.string().required(getErrorMessage('emailRequired'))
});

export const validateDates = (values) => {
  const errors = {};
  const { startMonth, startYear, endMonth, endYear } = values;

  if (endMonth !== 99) {
    if (!endMonth) {
      errors.endMonth = getErrorMessage('endMonthRequired');
    }
    if (!endYear) {
      errors.endYear = getErrorMessage('endYearRequired');
    }
    if (startMonth && startYear && endMonth && endYear) {
      if (endMonth !== '99' && endYear !== 99) {
        const startMonthIndex = monthNames.indexOf(startMonth);
        const endMonthIndex = monthNames.indexOf(endMonth);

        const startDate = new Date(startYear, startMonthIndex);
        const endDate = new Date(endYear, endMonthIndex);

        if (endDate < startDate) {
          errors.dates = getErrorMessage('endDateBeforeStartDate');
        }
      }
    }
  }

  return errors;
};

export const contactUsSchema = Yup.object().shape({
  fullName: Yup.string().max(50, getErrorMessage('fullNameMax')).required(getErrorMessage('fullNameRequired')),
  email: Yup.string().email(getErrorMessage('emailInvalidGD')).required(getErrorMessage('emailRequiredGD')),
  message: Yup.string().max(500, getErrorMessage('messageMax')).required(getErrorMessage('messageRequired'))
});
