import search from '../../../assets/images/search.svg';
import location from '../../../assets/images/location.svg';
import suitcase from '../../../assets/images/suitcase.svg';
import dollarSign from '../../../assets/images/dollarSign.svg';
import { useSelector } from 'react-redux';
import DropdownMenu from '../../../common/dropdown';
import {
  datePostedOptions,
  experienceOptions,
  jobTypeOptions,
  salaryOptions,
  workPolicyOption
} from '../../../constant/dropdownConstOptions';
import { useState } from 'react';
import DropdownMenuLang from '../../../common/dropdownLang';

const JobFilter = ({ filters, setFilters }) => {
  const lang = useSelector((state) => state.language.lang);
  const [searchInput, setSearchInput] = useState(filters);
  const [reset, setReset] = useState(false);

  const handleDatePostedSelect = ({ value }) => {
    setFilters({ ...filters, datePosted: value });
  };

  const handleSalarySelect = ({ value }) => {
    console.log('Selected Date Posted Value:', value);
    setFilters({ ...filters, salary: value });
  };

  const handleJobTypeSelect = ({ value }) => {
    console.log('Selected Date Posted Value:', value);
    setFilters({ ...filters, jobType: value });
  };

  const handleExperienceSelect = ({ value }) => {
    console.log('Selected Experience Value:', value);
    setFilters({ ...filters, experienceRequired: value });
  };

  const handleWorkLocationSelect = ({ value }) => {
    console.log('Selected Work Location Value:', value);
    setFilters({ ...filters, workPolicy: value });
  };

  const onChangeTextField = (e) => {
    const { name, value } = e.target;
    setSearchInput({ ...searchInput, [name]: value });
  };

  const submitSearchClick = () => {
    setFilters({ ...filters, ...searchInput });
  };

  const handleClearFilters = () => {
    // Reset the filters to initial state or default values
    setFilters({
      jobTitle: ''
    });
    setSearchInput({ jobTitle: '', companyLocation: '' });
    setReset(true);
    setTimeout(() => setReset(false), 0);
  };

  return (
    <div className="flex flex-col justify-center items-center px-0 md:px-10 py-8 border-solid border-b-2 w-full bg-white">
      <div className="flex flex-wrap justify-center">
        <div className="relative flex items-start">
          <span className="absolute inset-y-0 left-0 flex items-center pl-5 pb-4">
            <img src={search} alt="search" />
          </span>
          <input
            type="text"
            name="jobTitle"
            placeholder="UX Designer"
            value={searchInput?.jobTitle}
            onChange={onChangeTextField}
            className="pl-12 pr-14 h-12 rounded-l-md mb-4 shadow-filter-field"
          />
        </div>
        <div className="relative">
          <span className="absolute inset-y-0 left-0 flex items-center pl-5 pb-4">
            <img src={location} alt="location" />
          </span>
          <input
            type="text"
            name="companyLocation"
            placeholder="Singapore"
            value={searchInput?.companyLocation}
            onChange={onChangeTextField}
            className="pl-12 pr-14 h-12 mb-4 shadow-filter-field"
          />
        </div>
        {/* <div className="relative">
          <span className="absolute inset-y-0 left-0 flex items-center pl-5 pb-4">
            <img src={suitcase} alt="suitcase" />
          </span>
          <input
            type="text"
            name="jobType"
            placeholder={lang.generalJobType}
            onChange={onChangeTextField}
            className="pl-12 pr-14 py-3 mb-4 shadow-filter-field"
          />
        </div> */}
        {/* <div className="relative">
          <span className="absolute inset-y-0 left-0 flex items-center pl-5 pb-4">
            <img src={dollarSign} alt="dollarSign" />
          </span>
          <input
            type="text"
            name="salary"
            placeholder={lang.generalSalaryRange}
            onChange={onChangeTextField}
            className="pl-12 pr-14 py-3 mb-4 shadow-filter-field"
          />
        </div> */}
        <button
          type="button"
          onClick={submitSearchClick}
          className="pl-12 pr-14 h-12 rounded-md md:rounded-none md:rounded-r-md mb-4 text-sm font-bold text-white bg-black"
        >
          {lang.generalSearch}
        </button>
      </div>
      <div className="flex flex-wrap items-center justify-center gap-4 px-0 md:px-10 lg:px-20">
        <div className="text-sm font-bold font-nunito text-black">{lang.generalFilterBy}:</div>
        <DropdownMenu
          options={datePostedOptions}
          onSelect={handleDatePostedSelect}
          placeholder={lang.generalDatePosted}
          resetValue={reset}
        />
        <DropdownMenu
          options={salaryOptions}
          onSelect={handleSalarySelect}
          placeholder={lang.generalSalary}
          resetValue={reset}
        />
        <DropdownMenuLang
          options={jobTypeOptions}
          onSelect={handleJobTypeSelect}
          placeholder={lang.generalJobType}
          resetValue={reset}
        />
        <DropdownMenuLang
          options={experienceOptions}
          onSelect={handleExperienceSelect}
          placeholder={lang.generalExperienceLevel}
          resetValue={reset}
        />
        <DropdownMenuLang
          options={workPolicyOption}
          onSelect={handleWorkLocationSelect}
          placeholder={`${lang.generalOnSite} / ${lang.generalRemote}`}
          resetValue={reset}
        />
        <button
          type="button"
          onClick={handleClearFilters}
          className="px-4 h-10 rounded-md text-sm font-bold text-white bg-black"
        >
          {lang.generalClearFilter}
        </button>
      </div>
    </div>
  );
};

export default JobFilter;
