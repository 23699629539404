import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { employerLogout } from '../actions/employerAction';
import { useNavigate } from 'react-router-dom';
import { userLogout } from '../actions/userAction';
import { useAuthContext } from '../context/authContext';
import { copyToClipboard } from '../utils/functions';

const linkUrl = process.env.REACT_APP_LINKURL;

const HeaderProfileDropdownMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setAuthUser } = useAuthContext();

  const lang = useSelector((state) => state.language.lang);
  const isUserLogin = useSelector((state) => state.userLogin);
  const isEmployerLogin = useSelector((state) => state.employerLogin);
  const user = useSelector((state) => state.user);

  const handleLogout = () => {
    if (isEmployerLogin === true) {
      handleEmployerLogout();
    } else if (isUserLogin === true) {
      handleCandidateLogout();
    }
  };

  const handleEmployerLogout = () => {
    console.log('click handleEmployerLogout');
    dispatch(employerLogout(navigate, setAuthUser));
  };

  const handleCandidateLogout = () => {
    console.log('click handleCandidateLogout');
    dispatch(userLogout(navigate, setAuthUser));
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex w-full justify-center items-center py-3 px-1 gap-x-5 text-oppty-grey-7 hover:bg-gray-50">
          <ChevronDownIcon color="black" className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {isUserLogin ? (
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
            <div className="py-1">
              {process.env.REACT_APP_ENVIRONMENT === 'PROD' && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-xl`}
                      onClick={() =>
                        window.open(`https://marketplace.oppty.ai/auth/${localStorage.getItem('token')}`, {
                          target: '_blank'
                        })
                      }
                    >
                      Marketplace
                    </div>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-xl`}
                    onClick={() => navigate('/profile')}
                  >
                    {lang.generalProfile}
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-xl`}
                    onClick={() => window.open(`/public/${user?.publicId}`, { target: '_blank' })}
                  >
                    {lang.generalPublicProfile}
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-xl`}
                    onClick={() => copyToClipboard(`${linkUrl}/public/${user?.publicId}`, 'Public URL Copied')}
                  >
                    {lang.generalCopyPublicURL}
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-xl`}
                    onClick={() =>
                      copyToClipboard(`${linkUrl}/candidate-sign-up/${user?.publicId}`, 'Invite Friend URL Copied')
                    }
                  >
                    {lang.generalInviteFriend}
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-xl`}
                    onClick={() => navigate('/profile/saved-jobs')}
                  >
                    {lang.generalSavedJobs}
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-xl`}
                    onClick={() => navigate('/profile/applied-jobs')}
                  >
                    {lang.generalJobApplications}
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-xl`}
                    onClick={() => navigate('/profile/interviews')}
                  >
                    {lang.generalInterview}
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-xl`}
                    onClick={() => navigate('/profile/messages')}
                  >
                    {lang.generalMessages}
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-xl`}
                    onClick={() => navigate('/companies')}
                  >
                    {lang.generalCompanies}
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-xl`}
                    onClick={() => navigate('/articles')}
                  >
                    {lang.generalArticles}
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-xl`}
                    onClick={() => navigate('/profile/settings')}
                  >
                    {lang.generalSettings}
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={`${
                      active ? 'bg-red-100 text-red-900' : 'text-red-700'
                    } block px-4 py-2 text-xl border-t-2`}
                    onClick={handleLogout}
                  >
                    {lang.generalLogout}
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        ) : (
          isEmployerLogin && (
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none cursor-pointer">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} block px-4 py-2 text-xl`}
                      onClick={() => navigate('/employer/dashboard')}
                    >
                      {lang.generalGoToDashboard}
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={`${
                        active ? 'bg-red-100 text-red-900' : 'text-red-700'
                      } block px-4 py-2 text-xl border-t-2`}
                      onClick={handleLogout}
                    >
                      {lang.generalLogout}
                    </div>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          )
        )}
      </Transition>
    </Menu>
  );
};

export default HeaderProfileDropdownMenu;
