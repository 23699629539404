import WorkExperienceCard from './workExperienceCard';

const WorkExperience = ({ user }) => {
  return (
    <div className="w-full flex flex-col relative">
      <div className="p-10 lg:p-16 z-10">
        <div className="text-[#604E56] pb-10">
          <div className="text-5xl leading-none font-extrabold">Work Experience</div>
        </div>
        <div className="flex flex-row flex-wrap w-full gap-y-5 bg-[#FFF6ED] p-10">
          {user?.workExperiences?.map((work, index) => {
            return <WorkExperienceCard key={index} work={work} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default WorkExperience;
