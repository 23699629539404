import ReactPlayer from 'react-player';

const Video = ({ user }) => {
  return (
    <div className="w-full flex flex-col p-10 lg:p-16 shadow-md">
      <div className="text-5xl font-bold text-black">Video</div>
      <div className="text-xl mb-10 text-black">Check out my video</div>
      <div className="flex flex-col pt-5 w-fullr">
        <div className="bg-template2-orange-1/20 w-full lg:w-[550px] border-template2-orange-1 border-2">
          <ReactPlayer url={user?.video?.videoUrl} controls={true} width="100%" />
        </div>
      </div>
    </div>
  );
};

export default Video;
