import SkillComponent from './skillComponent';
import { ReactComponent as Design } from '../../../assets/templates/template2/aboutMeDesign.svg';

const AboutMeSection = ({ user }) => {
  return (
    <div className="relative flex flex-col bg-template2-orange-2">
      <div className="flex flex-col p-5 lg:p-16">
        <div className="w-full flex flex-col gap-y-20 border-b-2 border-black/30 pb-20">
          <div className="text-[50px] font-bold">About Me</div>
          {user?.aboutMe && (
            <div className="flex flex-col lg:flex-row w-full gap-y-5">
              <div className="w-full lg:w-1/2 text-[40px] leading-none">Short Description</div>
              {/* <div className="w-full lg:w-1/2">{user?.aboutMe}</div> */}
              <div className="w-full lg:w-1/2" dangerouslySetInnerHTML={{ __html: user?.aboutMe }}></div>
            </div>
          )}
        </div>
        <div className="w-full flex flex-col gap-y-20 border-b-2 border-black/30 py-20">
          <div className="flex w-full flex-col lg:flex-row gap-y-5">
            <div className="w-full lg:w-1/2 text-[40px] leading-none">Personal Info</div>
            <div className="w-full lg:w-1/2 flex flex-col gap-y-5 text-xl">
              <div className="flex gap-x-5">
                <div className="font-semibold">
                  Name:{' '}
                  <span className="font-normal">
                    {user?.firstName} {user?.lastName}
                  </span>
                </div>
                {user?.country && (
                  <div className="font-semibold">
                    Nationality: <span className="font-normal">{user?.country}</span>
                  </div>
                )}
              </div>
              {user?.languages?.length > 0 && (
                <div className="flex gap-x-5">
                  <div className="font-semibold">
                    Languages:{' '}
                    {user?.languages?.map((lang, index) => (
                      <span className='font-normal' key={index}>
                        {lang?.lang}
                        {index < user.languages.length - 1 && ', '}
                      </span>
                    ))}
                  </div>
                </div>
              )}
              <div className="flex gap-x-5">
                <div className="font-semibold">
                  email: <span className="font-normal">{user?.email}</span>
                </div>
              </div>
              {user?.address && (
                <div className="flex gap-x-5">
                  <div className="font-semibold">
                    Location:{' '}
                    <span className="font-normal">
                      {user?.address}, {user?.city}, {user?.state}, {user?.country} {user?.postal}
                    </span>
                  </div>
                </div>
              )}
              <div className="flex flex-wrap gap-x-5 gap-y-2">
                {user?.socials?.map((social, index) => {
                  return (
                    <div className="font-semibold flex gap-x-3" key={index}>
                      {social?.social}:{' '}
                      <div
                        onClick={() => window.open(social?.link, '_blank')}
                        className="font-normal hover:text-oppty-blue-2 hover:underline cursor-pointer"
                      >
                        {social?.link}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {user?.skills?.length > 0 && (
          <div className="w-full flex flex-col gap-y-20 border-black/30 pt-20">
            <div className="flex w-full flex-col lg:flex-row gap-y-5">
              <div className="w-full lg:w-1/2 text-[40px] leading-none">Skills</div>
              <div className="w-full lg:w-1/2 flex flex-wrap gap-x-20 gap-y-10 justify-center">
                {user?.skills?.map((skill, index) => {
                  let percentage = 0;
                  if (skill?.level === 'Beginner') {
                    percentage = 35;
                  } else if (skill?.level === 'Intermediate') {
                    percentage = 75;
                  } else {
                    percentage = 100;
                  }
                  return <SkillComponent key={index} skill={skill} percentage={percentage} />;
                })}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="absolute bottom-0 h-72 w-72 -left-8">
        <Design className="w-full h-full hidden xl:flex" />
      </div>
    </div>
  );
};

export default AboutMeSection;
